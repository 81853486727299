const useJsonLdAggregateRating = () => {
  const state = useState<object|null>(() => null)

  const setData = (data: object|null) => {
    state.value = data
  }

  const getAssembledObject = (): object|null => {
    return state.value
  }

  return {
    setData,
    getAssembledObject
  }
}

export default useJsonLdAggregateRating
